import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { Box, Col, Flex, Text } from '../Ui';
import Soglia from './soglia/Soglia';
import moment from 'moment';
function Situation() {
  const data = useSelector(state => state.user.user.individual_agreement_datas);
  const { individual_agreement_accepted_date, individual_agreement_file } = useSelector(
    state => state.user.user?.userinfo || {},
  );
  const cluser_name = useSelector(state => state.user.user.userinfo?.cluster_name);
  const listDate = [
    'dal 02/01/2024 al 01/04/2024',
    'dal 02/04/2024 al 30/06/2024',
    'dal 01/07/2024 al 30/09/2024',
    'dal 01/10/2024 al 31/12/2024',
  ];
  return (
    <Container>
      <Text bold size={20} primary>
        Data ultimo aggiornamento: {data?.date}
      </Text>
      <Text bold size={20} className='mt-10' primary>
        Il tuo accordo del{' '}
        {moment(new Date(individual_agreement_accepted_date)).format('DD/MM/YYYY')}:
        <a href={individual_agreement_file} download target='_blank' rel='noopener noreferrer'>
          {' '}
          Download
        </a>
      </Text>
      <Box className='main-target'>
        <div className='row mb-10'>
          <Text>{data?.main_target?.target_label} </Text>
          <Text bold className='price'>
            {data?.main_target?.target}
          </Text>
        </div>
        <div className='row'>
          <Text>{data?.main_target?.progressivo_label} </Text>
          <Text bold className='price'>
            {data?.main_target?.progressivo}
          </Text>
        </div>
        <div className='progress'>
          <div
            className='track'
            style={{
              width: `${
                data?.main_target?.percentuale_completamento > 100
                  ? 100
                  : data?.main_target?.percentuale_completamento
              }%`,
              ...(data?.main_target?.percentuale_completamento >= 100 && {
                backgroundColor: 'green',
              }),
            }}
          ></div>
        </div>
      </Box>
      {/*       <Flex gap={20} className='targets'>
        {data?.targets.map((item, i) => (
          <div key={i} className='target'>
            <Text bold size={16} align={'right'} style={{ marginBottom: '5px' }}>
              {listDate[i]}
            </Text>
            <Box>
              <div className='row mb-10'>
                <Text>{`Obiettivo fatturato Loacker Canvass ${i + 1}: `}</Text>
                <Text color='#A86E3C' bold className='price'>
                  {item.target}
                </Text>
              </div>
              <div className='row mb-10'>
                <Text>{`Fatturato progressivo Loacker Canvass ${i + 1}: `}</Text>
                <Text color='#A86E3C' bold className='price'>
                  {item.progressivo}
                </Text>
              </div>
              <div className='progress'>
                <div
                  className='track'
                  style={{
                    width: `${
                      item.percentuale_completamento > 100 ? 100 : item.percentuale_completamento
                    }%`,
                    ...(item.percentuale_completamento >= 100 && { backgroundColor: 'green' }),
                  }}
                ></div>
              </div>

              <div className='points'>
                <Text>
                  Punti: <strong>{item.incentivo_da_confermare}</strong>
                </Text>
              </div>
            </Box>
          </div>
        ))}
      </Flex> */}
    </Container>
  );
}

const Container = styled.div`
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  padding: 30px 0;
  .points {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .progress {
    height: 30px;
    margin-top: 20px;
    position: relative;
    background-color: #eee;
    overflow: hidden;

    .track {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: #c1bdbd;
    }
  }
  .row {
    display: flex;
    gap: 10px;
    padding-left: 5px;
    align-items: center;

    .price {
      white-space: nowrap;
    }
  }
  .main-target {
    padding: 15px 10px;
    margin-top: 30px;
    ${respondTo.sm`
      width: calc(50% - 15px );
      
    `}
  }
  .targets {
    // border-top: 1px solid #707070;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
  }
  .target {
    width: 100%;
    //padding: 15px 10px;
    flex-grow: 1;
    ${respondTo.sm`
      width: 48%
    `};
  }
`;

export default Situation;
